import React, { useState, useEffect, Suspense, lazy, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';
import SideMenu from './Layout/SideMenu';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router';
import Loading from './UI/Loading';
import { useSelector } from 'react-redux';
const ViewportOptions = lazy(() => import('../components/ViewportOptions'));
const HomeTimesPage = lazy(() => import('../pages/HomeTimesPage'));

// eslint-disable-next-line no-unused-vars
function Layout({ className, children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState(false);
  const [menu, setMenu] = useState(Boolean(searchParams.get('menu')));
  const { viewport, leftSidebar, rightSidebar } = useSelector(state => state.params);

  const hideMenu = () => {
    searchParams.delete('menu');
    setSearchParams(searchParams);
  };

  const showMenu = () => {
    searchParams.set('menu', 'on');
    setSearchParams(searchParams);
  };

  const handleClick = (type, id, mode) => {
    if (type === 'ViewportOptions') {
      if (mode === 'cls') {
        searchParams.delete('viewport');
        setSearchParams(searchParams);
        return;
      }
    } else if (type === 'presets') {
      if (mode === 'close') {
        searchParams.delete('viewport');
        setSearchParams(searchParams);
        return;
      }
    } else if (type === 'hometimes') {
      if (mode === 'close') {
        searchParams.delete('hometimes');
        setSearchParams(searchParams);
        return;
      }
    }
  };

  useEffect(() => {
    const _view = searchParams.get('view');
    const _menu = Boolean(searchParams.get('menu'));

    if (['fleet', 'freight', 'externalFreight'].includes(_view) || _menu) {
      setOpened(true);
    } else {
      setOpened(null);
    }

    setMenu(_menu);
  }, [searchParams, menu]);

  return (
    <Box className={opened ? 'layout opened' : 'main'}>
      <SideMenu handleClick={handleClick} />
      <div
        className={`page page--${className}${leftSidebar ? ' left-drawer' : ''}${rightSidebar ? ' right-drawer' : ''}`}
      >
        {children}

        {viewport === 'options' && (
          <div className="viewport-container">
            <Suspense fallback={<Loading />}>
              <ViewportOptions handleClick={handleClick} />
            </Suspense>
          </div>
        )}
        {viewport === 'hometimes' && (
          <div className="viewport-container ht">
            <Suspense fallback={<Loading />}>
              <HomeTimesPage />
            </Suspense>
          </div>
        )}
      </div>
    </Box>
  );
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
