import React, { createContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getDesignTokens } from '../theme';
import { getLocalStorageData } from '../utils';

const ThemeContext = createContext({
  appTheme: '',
  setAppTheme: appTheme => {},
});

const AppThemeProvider = ({ children }) => {
  const [appTheme, setAppTheme] = useState(getLocalStorageData('userConfig')?.theme || 'default');
  const toastTheme = appTheme === 'color-blind' ? 'dark' : 'light';

  if (appTheme) {
    document.body.className = '';
    document.body.classList.add(`theme-${appTheme}`);
  }

  return (
    <ThemeContext value={{ appTheme, setAppTheme }}>
      <div className={`theme-${appTheme}`}>
        <ThemeProvider theme={getDesignTokens(appTheme)}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ToastContainer limit={5} autoClose={2000} style={{ right: '8px', top: '55px' }} theme={toastTheme} />
            {children}
          </StyledEngineProvider>
        </ThemeProvider>
      </div>
    </ThemeContext>
  );
};

AppThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ThemeContext, AppThemeProvider };
