import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], filters: [] };

const clusterAnalysisSlice = createSlice({
  name: 'clusterAnalysis',
  initialState,
  reducers: {
    setItem(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters.push(action.payload);
    },
    clearFilters(state, action) {
      if (action.payload) {
        state.filters = state.filters.filter(filter => !action.payload.includes(filter.fikterType));
      } else {
        state.filters = [];
      }
    },
    deleteLastFilter(state) {
      state.filters.pop();
    },
  },
});

export const clusterAnalysisActions = clusterAnalysisSlice.actions;
export default clusterAnalysisSlice;
