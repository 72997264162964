import React from 'react';
import { Route, Navigate } from 'react-router';
import { routeConfig } from './config/routeConfig';

// Generate routes from config
const routes = Object.entries(routeConfig.routes).map(([key, config]) => (
  <Route key={key} path={config.path} element={config.component()} />
));

// Add default redirect
routes.push(<Route key="default" path="*" element={<Navigate to={routeConfig.defaultRoute} replace />} />);

export default routes;
